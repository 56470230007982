<template>
	<div />
</template>

<script>
export default {
	name: "ServicesTable"
}
</script>

<style scoped>

</style>
